import {MD5, enc} from "crypto-js";
import {FILE_PATH} from "./contants";
import moment from "moment";

export async function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function pwdConvert(pwd) {
  return MD5(pwd).toString(enc.Base64);
}

export function isFileHash(hash) {
  return /^[0-9a-f]{32}#\w{3,4}:\d+$/.test(hash)
}

export function fileHashToPath(hash) {
  if (/^[0-9a-f]{32}#\w{3,4}:\d+$/.test(hash)) {
    const value = hash.substring(0, hash.indexOf(':'));
    const values = value.split("#");
    const result = [
      FILE_PATH,
      values[0].substring(0, 1),
      values[0].substring(1, 3),
      values[0].substring(3)
    ].join('/');
    return values.length > 1 ? `${result}.${values[1]}` : result;
  }
  return hash;
}

export function getRecentMonth(n) {
  const month = moment(new Date()).subtract(n, 'months').format('YYYY/MM/DD');
  return month;
}

export function getRecentYear(n) {
  if (n === 0) {
    const today = new Date();
    const year = today.getFullYear();
    return `${year}/01/01`;
  }
  const date = moment(new Date()).subtract(n, 'years').format('YYYY/MM/DD');
  return date;
}

export function getDates(date, dates) {
  let dateIndex = 0;
  for (let i = 0; i < dates.length; i++) {
    const item = dates[i];
    if (!compDateTime(date, item)) {
      dateIndex = i;
      break;
    }
  }
  return {dates: dates.slice(dateIndex), index: dateIndex};
}

export function compDateTime(d1, d2) {
  const date1 = new Date(Date.parse(d1));
  const date2 = new Date(Date.parse(d2));
  return date1 > date2;
}


import request from "../utils/request";
import {action, computed, observable} from "mobx";
import {loading} from "@chaosoft/mobx-loading";
import {Auth} from "@chaosoft/admin";
import {message} from "antd";
import {LocalStore} from "./local.store";
import {pwdConvert} from "../utils/common";

export class AdminStore extends LocalStore {

  @observable
  admin = {}

  @computed
  get loginInfo() {
    return this.getItem("login");
  }

  get storeName() {
    return "admin";
  }

  @computed
  get isLogin() {
    return !!this.loginInfo?.token;
  }

  @computed
  get isLoadSelf() {
    return !!this.admin.key;
  }

  constructor() {
    super();
    if (this.isLogin) {
      Auth.reloadAuth(true);
    }
  }

  @action
  login = async ({loginType, auto, ...rest}) => {
   try {
      if (loginType === 'PASSWORD') {
        const {password} = rest;
        rest.password = pwdConvert(password);
      }
      const loginInfo = await AdminStore.postLogin({
        certType: loginType,
        tokenType: auto ? 'LONG_TIME' : 'TEMPORARY',
        content: {
          userType: 'ADMIN',
          ...rest,
        }
      });
      this.setItem("login", loginInfo);
      Auth.reloadAuth(true);
   } catch (error) {
      message.error(error.message);
   }
  }

  @action
  logout = async () => {
    try {
      await AdminStore.postLogout();
    } catch (e) {
    } finally {
      this.removeLocalLogin();
    }
  }

  @action
  removeLocalLogin = () => {
    this.removeItem("login");
    Auth.reloadAuth(false);
    this.admin = {};
    location.reload();
  }


  @action
  loadLoginAdmin = async () => {
    if (!this.isLogin) {
      message.error("当前尚未登录！！！")
    } else {
      this.admin = await AdminStore.getSelf();
    }
  }

  @loading('admin/sendCode')
  @action
  sendEmailCode = async (email) => {
    await AdminStore.postResetPwdCode({email});
  }

  @action
  changePassword = async ({change, origin}) => {
    await AdminStore.postChangePwd({
      change: change && pwdConvert(change),
      origin: origin && pwdConvert(origin)
    });
    message.info({
      type: 'success',
      content: '密码修改成功！请重新登入',
    });
    setTimeout(async () => {
      await this.logout();
    }, 1000);
  }

  // 忘记密码，重置密码
  @action
  resetPassword = async ({change, ...params}) => {
    await AdminStore.postResetPwd({
      ...params,
      change: change && pwdConvert(change),
    });
    message.success('密码重置成功！即将跳转到登录页');
  }

  @action
  activiteAccount = async (password, token) => {
    await AdminStore.postActiviteAccount({
      passwd: password && pwdConvert(password),
      token
    });
  }

  static postLogin = async (data) => {
    return request.post('/admin/login', {data})
  }

  static postLogout = async () => {
    return request.post('/admin/logout')
  }

  static getSelf = async () => {
    return request.get('/admin/self')
  }

  static postResetPwd = async (data) => {
    return request.post('/admin/reset-pwd', {data})
  }

  static postResetPwdCode = async (params) => {
    return request.post('/admin/reset-pwd-code',{requestType: 'form-data', params})
  }

  static postActiviteAccount = async (data) => {
    return request.post('/admin/activate-account', {data})
  }

  static postChangePwd = async (data) => {
    return request.post('/admin/change-pwd', {data})
  }

}

export default new AdminStore();

import {action, computed, observable} from "mobx";
import {loading} from "@chaosoft/mobx-loading";
import {message} from 'antd';
import request from "../utils/request";
import moment from "moment";
import {InvestmentGroupStore} from "./investmentGroup.store"

export class ProjectEditStore {
  @observable projects = [];

  @computed get hasTableDatas() {
    return this.tableDatas.list.length > 0;
  }

  @action
  loadProjects = async () => {
    const res = await InvestmentGroupStore.getProjects();
    this.projects = res;
  }

  @action
  loadGroups = async (query) => {
    const res = await InvestmentGroupStore.getGroups(query);
    return res;
  }

  @action
  uploadMptProject = async (params) => {
    const res = await ProjectEditStore.postProject(params);
    if (res && res.success) {
      message.info({
        type: 'success',
        content: '提交成功！即将跳转到列表页',
      });
      setTimeout(() => {
        location.href = `${process.env.PUBLIC_URL}/project`;
      }, 2000);
    }
  }

  static postProject = async (data) => {
    return request.post('/manage/project', {data});
  }

}

export default new ProjectEditStore();

import {action, computed, observable} from "mobx";
import {loading} from "@chaosoft/mobx-loading";
import request from "../utils/request";
import moment from "moment";

export class InvestmentGroupStore {

  @observable records = {
    list: [],
    pagination: {},
    params: {}
  };

  @observable projects = [{projectName: '全部', key: 'ALL'}];

  @observable tableDatas = {
    list: [],
    pagination: {},
  }

  @computed get hasTableDatas() {
    return this.tableDatas.list.length > 0;
  }

  @action
  loadProjects = async () => {
    const res = await InvestmentGroupStore.getProjects();
    this.projects = [...this.projects, ...res];
  }

  @action
  loadGroups = async (query) => {
    const res = await InvestmentGroupStore.getGroups(query);
    return res;
  }

  @action
  loadRecords = async ({pageable, params} = {}) => {
    const {content, ...page} = await InvestmentGroupStore.getRecords({
      ...this.records.params,
      ...(params || {}),
      ...{page: 0, size: 10},
      ...(pageable ? {...pageable, page: pageable.page - 1} : {})
    });
    this.records = {
      list: content.map(({createTime, ...item}) => ({
        ...item,
        createTime: moment(createTime).format('YYYY-MM-DD HH:mm')
      })),
      pagination: {...page, current: page.current + 1},
      params: {...this.records.params, ...(params || {})}
    }

    this.tableDatas = {
      list: this.records.list,
      pagination: this.records.pagination,
    }
  }

  @action
  loadMptFiles = async (query) => {
    const res = await InvestmentGroupStore.getMptFiles(query);
  }

  
  // 项目列表
  static getProjects = async () => {
    return request.get(`/manage/projects`);
  }

  // 项目下投组列表
  static getGroups = async (query) => {
    return request.get(`/manage/projects/${query}`);
  }

  // 上传记录
  static getRecords = async (params) => {
    return request.get(`/manage/records`, {params});
  }

  // 投组文件下载
  static getMptFiles = async (fileId) => {
    return request.get(`/manage/download/mpt/${fileId}`);
  }
    

}

export default new InvestmentGroupStore();

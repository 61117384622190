import React from "react";
import {AuthRoute} from "@chaosoft/admin";
import {Route as ReactRoute} from "react-router-dom";
import {useStores} from "./stores/hooks";
import {CloudUploadOutlined, DatabaseOutlined, SmileOutlined, FundProjectionScreenOutlined, UsergroupAddOutlined} from "@ant-design/icons";

const LoginRoute = (props) => {
  const {adminStore} = useStores();
  return (
    <AuthRoute {...props} access={!adminStore.isLogin} redirect="/">
      {props.children}
    </AuthRoute>
  )
}

const InitPwdRoute = (props) => {
  const {adminStore} = useStores();
  return (
    // <AuthRoute {...props} access={adminStore.isLogin && (adminStore.loginInfo?.tempPwd)} redirect={adminStore.isLogin ? "/" : "/login"}>
    //   {props.children}
    // </AuthRoute>
    <AuthRoute {...props} access={!adminStore.isLogin} redirect={adminStore.isLogin ? "/" : "/login"}>
      {props.children}
    </AuthRoute>
  )
}

const ActivitePwdRoute = (props) => {
  const {adminStore} = useStores();
  return (
    <ReactRoute {...props}  redirect={adminStore.isLogin ? "/" : "/login"}>
      {props.children}
    </ReactRoute>
  )
}

const ChangePwdRoute = (props) => {
  const {adminStore} = useStores();
  return (
    <AuthRoute {...props} access={adminStore.isLogin}  redirect={adminStore.isLogin ? "/" : "/login"}>
      {props.children}
    </AuthRoute>
  )
}

const BasicRoute = (props) => {
  const {adminStore} = useStores();
  return (
    <AuthRoute {...props} redirect={adminStore.isLogin ? "/" : "/login"}>
      {props.children}
    </AuthRoute>
  )
}

export default [{
  path: '/login',
  component: './pages/admin/login',
  Route: LoginRoute,
  exact: true
}, {
  path: '/initPwd',
  component: './pages/admin/init-pwd',
  Route: InitPwdRoute,
  exact: true
}, {
  path: '/activite',
  component: './pages/admin/activite-account',
  Route: ActivitePwdRoute,
  exact: true
}, {
  path: '/changePwd',
  component: './pages/admin/change-pwd',
  Route: ChangePwdRoute,
  exact: true
}, {
  path: '/',
  component: './layout/basic-layout',
  Route: BasicRoute,
  routes:  [{
    path: '/',
    redirect: '/home',
    exact: true
  }, {
    path: '/home',
    name: '首页',
    icon: <SmileOutlined />,
    component: './pages/home',
    exact: true
  },{
    path: '/investmentGroup',
    name: '数据汇入管理',
    icon: <DatabaseOutlined />,
    routes:[ {
      path: '/investmentGroup',
      redirect: '/investmentGroup/list',
      exact: true,
    },{
      path: '/investmentGroup/list',
      name: '汇入记录',
      icon:<CloudUploadOutlined />,
      component: './pages/investment-group/investment-group',
      exact: true,
    },
    {
      path: '/investmentGroup/edit',
      name: '汇入数据',
      icon:<CloudUploadOutlined />,
      component: './pages/investment-group/investment-group-edit',
      exact: true,
    }]
  },{
    path: '/project',
    name: '投组项目管理',
    icon: <FundProjectionScreenOutlined />,
    routes:[ {
      path: '/project',
      redirect: '/project/list',
      exact: true,
    },{
      path: '/project/list',
      name: '项目列表',
      icon:<CloudUploadOutlined />,
      component: './pages/project/project-list',
      exact: true,
    }
    ,{
      path: '/project/edit',
      name: '新建投组项目',
      icon:<CloudUploadOutlined />,
      component: './pages/project/project-edit',
      exact: true,
    }
  ]
  },{
    path: '/permission',
    name: '系统权限管理',
    icon: <UsergroupAddOutlined />,
    routes:[ {
      path: '/permission',
      redirect: '/permission/user-list',
      exact: true,
    },{
      path: '/permission/user-list',
      name: '账号管理',
      icon:<CloudUploadOutlined />,
      component: './pages/permission/user-list',
      exact: true,
    }]
  }]},
]

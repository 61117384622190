import {action, computed, observable} from "mobx";
import {loading} from "@chaosoft/mobx-loading";
import request from "../utils/request";
import {stringifyUrl} from "query-string";
import {message}  from "antd";

export class UserStore {

  @observable datas = {
    list: [],
    pagination: {},
    params: {}
  }

  @computed get tableDatas() {
    const {list, pagination} = this.datas;
    return {list, pagination}
  }

  @computed get hasMorePage() {
    return this.datas?.pagination?.totalPages > 1
  }

  @loading('user/find')
  @action
  load = async ({pageable, params} = {}, force) => {
    const {content, ...page} = await UserStore.findUser({
      ...(force ? {} : this.datas.params),
      ...(params || {}),
      ...{page: 0, size: 20},
      ...(pageable ? {...pageable, page: pageable.page - 1} : {})
    });
    let data = [];
    if (content.length > 0) {
      data = content.map((value) => {
       return  {
        ...value,
       status: {
         disabled: value.disabled,
         forcePwd: value.forcePwd,
       }}
      });
    }
    this.datas = {
      list: data,
      pagination: {...page, current: page.current + 1},
      params: {...this.datas.params, ...(params || {})}
    }
    // const page = {
    //   current: 0,
    //   total: 1,

    // }
    // this.datas = {
    //   list: [{id: 1, name: 'zyrz', mail: '123@123.com', role: 'MANAGER', status: 'ACTIVITY', createTime: '2022-12-13'},
    //   {id: 2, name: 'thgroup', mail: '1202328843@123.com', role: 'ADMIN', status: 'INVITING', createTime: '2022-12-13'},
    //   {id: 3, name: 'fafa', mail: '82992@123.com', role: 'NORMAL', status: 'DISABLE', createTime: '2022-12-13'},
    //   {id: 4, name: 'lili', mail: '10852@123.com', role: 'NORMAL', status: 'ACTIVITY', createTime: '2022-12-13'}],
    //   pagination: {...page, current: page.current + 1},
    //   params: {...this.datas.params, ...(params || {})}
    // }
  }

  @loading('user/find')
  @action
  refresh = async () => {
    const {current: page = 1, pageSize: size = 10} = this.datas.pagination;
    await this.load({
      pageable: {page, size}
    });
  }

  @loading('user/save')
  @action
  save = async (data) => {
    try {
      await UserStore.postUser(data);
      await this.refresh();
      message.info({
        type: 'success',
        content: '邀请成功！',
      });
      
    } catch (error) {
      message.error(error.message);
    }
   
  }

  @loading('user/save')
  @action
  setUserStatus = async (key, disabled) => {
    try {
      if (disabled) {
        await UserStore.deleteDisabled(key);
      } else {
        await UserStore.putDisabled(key);
      }
      await this.refresh();
    } catch (error) {
      message.error(error.message);
    }
  }

  @loading('user/save')
  @action
  deleteUser= async (key) => {
    await UserStore.delete(key);
    await this.refresh();
  }

  static findUser = async (query) => {
    return request.get(stringifyUrl({
      url: '/account',
      query
    }, {skipEmptyString: true, skipNull: true}))
  }

  // 添加账号
  static postUser = async (data) => {
    return request.post(`/admin/add-account`, {data});
  }

  // 删除用户
  static delete = async (key) => {
    return request.delete(`/account/${key}/delete`)
  }

  //禁用
  static putDisabled = async (key) => {
    return request.put(`/account/${key}/disabled`)
  }

  // 启用
  static deleteDisabled = async (key) => {
    return request.delete(`/account/${key}/disabled`)
  }

}

export default new UserStore();

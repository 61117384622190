import {action, computed, observable} from "mobx";
import {loading} from "@chaosoft/mobx-loading";
import request from "../utils/request";
import moment from "moment";

export class PerformanceStore                      {

  @observable dataDic = {
    ft3: [],
    ft6: [],
    ft9: []
  }

  @loading('performance/find')
  @action
  loadOverview = async (query) => {
    const res = await PerformanceStore.getOverview(query);
  }

  @action
  loadTrend = async (query) => {
    const res = await PerformanceStore.getTrend(query);
  }

  @action
  loadRiskType = async (query) => {
    const res = await PerformanceStore.getRiskType(query);
  }
  
  // 业绩总览
  static getOverview = async (query) => {
    return request.get(`/jrp/performance/overview/${query}`)
  }

  // 业绩走势
  static getTrend = async (query) => {
    return request.get(`/jrp/performance/trend/${query}`)
  }

  // 风险资产占比
  static getRiskType = async (query) => {
    return request.get(`/jrp/performance/riskType/${query}`)
  }

}

export default new PerformanceStore();

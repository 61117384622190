import {action, computed, observable} from "mobx";
import {loading} from "@chaosoft/mobx-loading";
import request from "../utils/request";
import moment from "moment";

export class ProjectStore {

  @observable records = {
    list: [],
    pagination: {},
    params: {}
  };

  @observable projects = [{projectName: '全部', key: 'ALL'}];

  @observable tableDatas = {
    list: [],
    pagination: {},
  }

  @computed get hasTableDatas() {
    return this.tableDatas.list.length > 0;
  }

  @action
  loadProjects = async ({pageable, params} = {}) => {
    const {content, ...page} = await ProjectStore.getProjects({
      ...this.records.params,
      ...(params || {}),
      ...{page: 0, size: 10},
      ...(pageable ? {...pageable, page: pageable.page - 1} : {})
    });
    this.records = {
      list: content.map(({createTime, ...item}) => ({
        ...item,
        createTime: moment(createTime).format('YYYY-MM-DD HH:mm')
      })),
      pagination: {...page, current: page.current + 1},
      params: {...this.records.params, ...(params || {})}
    }

    this.tableDatas = {
      list: this.records.list,
      pagination: this.records.pagination,
    }
  }

  @action
  refresh = async () => {
    const {current: page = 1, pageSize: size = 10} = this.tableDatas.pagination;
    await this.loadProjects({
      pageable: {page, size}
    });
  }

  @action
  remove= async (key) => {
    await ProjectStore.deleteProject(key);
    await this.refresh();
  }
  
  // 项目列表
  static getProjects = async (params) => {
    return request.get(`/manage/project`, {params});
  }

  // 删除项目
  static deleteProject = async (key) => {
    return request.delete(`/manage/project/${key}`);
  }
  

}

export default new ProjectStore();
